import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import { makeStyles, TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GetAiResponse, GetAiSuggestions, GetHotelPrompt } from './HotelChatBox.services';
import Typewriter from './TypeWriter';
import moment from 'moment';
import HotelAiResponses from './HotelAiResponses';
import { createTripThunk } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { URL_REPLACE } from '../RegExValidate';
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
})



const HotelChatBox_ = ({ lang, establishment, getMemberAreaMuverInfo, uuid, activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    React.useEffect(() => {
        if (uuid) {
            getMemberAreaMuverInfo(uuid)
        }
    }, [uuid])

    const classes = useStyles();

    const history = useHistory()
    const [chatResponses, setChatResponses] = React.useState([]);
    const [places, setPlaces] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [searching, setSearching] = React.useState(false);
    const listRef = useRef()
    const [showResults, setResults] = React.useState(false);
    const member = useSelector(state => state.Member);
    const avatarUrl = member?.memberArea?.memberMuver?.muverAvatar?.fullUrl;
    const [sessionId, setSessionId] = React.useState("");
    const [typeIndex, setTypeIndex] = React.useState(0)
    const [tripDest, setDestination] = React.useState()
    const [creating, setCreating] = React.useState(false)
    const dispatch = useDispatch()

    const handleMessage = React.useCallback((event) => {
        const val = event.target.value
        setMessage(val)
    }, []);


    React.useEffect(() => {
        setSessionId(Date.now())
        return (() => {
            setSessionId("")
        })
    }, [])


    const formattedHtml = (text) => {
        if (text && text?.length > 0) {
            const bold = /\*\*(.*?)\*\*/gm;
            // let html = text.replace(bold, '<em>$1</em>');  //bold title
            // html = html.replaceAll("[More details]", "") //remove details
            // html = html.replaceAll("[More Info]", "") //remove details
            // html = html.replaceAll("[More info]", "") //remove details
            // html = html.replace(/\[url\s?=\s?"?(.*?)"?\](.*?)\[\/url\]/g, '<a href="$1">$2</a>')
            return text
        }

        return text;
    }


    const sendMessage = async (event) => {
        if (event?.key === 'Enter' && message?.length > 0) {
            setSearching(true)

            let newMessages = [];
            let newchats = []
            let newResponses = [];
            let combineResponses = [...chatResponses];

            combineResponses.push([{
                sender: avatarUrl,
                text: message,
                isUser: true

            }])
            setChatResponses(combineResponses);

            if (!showResults) {
                setResults(true)
            }
            ////push user message first



            //push suggestion
            const hotelId = establishment?.generalInfo?.establishmentPricelineID;
            const result = await GetHotelPrompt({ message, member_id: uuid, prompt_session_id: sessionId, hotelId: hotelId });

            const messageResponse = result?.result?.prompt_response;
            const html = formattedHtml(messageResponse)


            if (html) {
                newMessages.push({
                    bot: true,
                    text: html ?? "Sorry i couldn't find a suggestion",

                })
            }


            ///for responses
            combineResponses.push(newMessages)
            setChatResponses(combineResponses)



            setSearching(false)
            setMessage("")
        }

    }


    const scrollToBottom = () => {
        try {
            listRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
        catch {

        }
    }


    const toggleResults = () => {
        setResults(!showResults)
    }




    const createTrip = React.useCallback(async () => {
        const destination = tripDest?.muv_destination ? [{
            ...tripDest?.muv_destination,
            duration: tripDest?.trip_duration || 3
        }] : null;

        if (destination) {
            setCreating(true)
            const trip = {
                destinations: destination,
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: ""

            }
            const newTrip = await dispatch(createTripThunk(trip));
            const addedTrip = newTrip.payload;
            if (addedTrip) {
                   const encryptedId = encryptID(addedTrip?.tripID)

                const url = "/trip/" + addedTrip?.tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
                history.push(url.toLowerCase())



                setCreating(false)
            }
            else {
                setCreating(false)
            }

            setTimeout(() => {
                setCreating(false)
            }, 8000);
        }

    }, [tripDest])


    const AIReponsesMemo = React.useCallback(() => {
        return (
            <HotelAiResponses createTrip={createTrip}  
                creating={creating} chatResponses={chatResponses} searching={searching} tripDestination={tripDest}
                showResults={showResults} scrollToBottom={scrollToBottom} is_mobile={is_mobile} listRef={listRef} />
        )
    }, [searching, creating, tripDest, showResults, is_mobile, listRef])




    React.useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 50);
    }, [chatResponses])


    const handleArrowClick = () => {
        toggleResults()
        sendMessage()

        if (showResults) {

            let combineResponses = [...chatResponses];

            combineResponses.push([{
                text: "",

            }])
            setChatResponses(combineResponses);

        }//fake end animation


        // if(showResults){ //clear messages
        //     setChatMessages([])
        //     setChatResponses([])
        //     setChatTexts([])
        // }
    }

    return (

        <div style={{ width: is_mobile ? "95%" : "100%", position: "fixed", bottom: 20, zIndex: 200 }}>

            {/* <AISuggestionsTexts /> */}
            {/* <AISuggestions/> */}
           <AIReponsesMemo />


            <div style={{}}>
                <div style={{ color: "#19BC9B", font: "18px/20px Futura Hv BT", zIndex: 1, marginLeft: is_mobile ? "8%" : "17%", position: "absolute", marginTop: 13, display: "inline-block" }}>
                    müvGPT
                </div>

                <TextField
                    classes={{ root: classes.customTextField }}
                    onKeyPress={sendMessage}
                    disabled={searching}
                    value={message}
                    style={{
                        background: "white",
                        borderRadius: 50,
                        border: "2px solid #19BC9B",
                        width: is_mobile ? "90%" : "70%",
                        marginLeft: is_mobile ? "5%" : "16%",
                        padding: 10,
                        paddingLeft: 110,
                        paddingRight: 70,
                        // border: '1px solid lightgrey',
                        font: "18px/20px Futura Lt BT",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    placeholder={'Ask anything about this hotel & its neighborhood: how to get there, are pets allowed, any good restaurants around?'}
                    id='aiTextBox'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleMessage}
                />
                <div onClick={handleArrowClick} style={{ marginLeft: -55, cursor: "pointer", position: "relative", marginTop: 10, display: "inline-block" }}>
                    <FontAwesomeIcon size="2x" icon={showResults ? faChevronDown : faChevronUp} style={{ color: 'lightgrey', marginRight: "5px" }} />
                </div>

                <div style={{         textShadow: " 1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey", color: "#19BC9B", font: "16px/20px Futura Lt BT", width: "100%", marginTop: 4, textAlign: "center" }}>
                    You will be able to generate an AI-personalized itinerary at the end of the booking process
                </div>

            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    logo_width: state.Setting.appMenu.logo.width,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    homepageSliderImage: state.Home.homepage_slider_image,
    homepageGeneralInfo: state.Home.homepage_general_info,
    is_mobile: state.Setting.is_mobile,
    establishment: state.FormBank.HotelSearch.establishment,
    activeTab: state.FormBank.BookingForm.activeTab,
})

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const HotelChatBox = connect(mapStateToProps, mapDispatchToProps)(HotelChatBox_)
export default React.memo(HotelChatBox)
